import React from "react"
import BlogPostCard from "./BlogPostCard"
import LabelText from "./LabelText"

const CornerstoneArticles = ({ cornerstonePosts }) => {
  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 mb-20">
      <LabelText className="mb-8 text-gray-700 text-center">
        UNSERE BELIEBTESTEN ARTIKEL 💯
      </LabelText>
      <div className="flex flex-wrap flex-col md:flex-row md:-mx-3">
        {cornerstonePosts.map((post, idx) => (
          <div key={idx} className="w-full md:w-1/2 lg:w-1/3 px-3">
            <div className="py-4">
              <BlogPostCard post={post} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CornerstoneArticles
