import React from "react"
import LabelText from "./LabelText"
import libroLogo from "../svg/libro-logo.svg"
import gigasetLogo from "../svg/gigaset-logo.svg"
import lancomeLogo from "../svg/lancome-logo.svg"
import kiehlsLogo from "../svg/kiehls-logo.png"
import petsPremiumLogo from "../svg/pets-premium.png"

const LogoSection = ({ heading }) => (
  <>
    <div className="bg-primary">
      <div className="max-w-7xl mx-auto pb-8 pt-8 px-4 lg:pb-24 sm:px-6 lg:px-8">
        <p className="text-center sm:py-4 text-xl font-semibold uppercase text-gray-600 tracking-wide">
          <LabelText className="text-white">{heading}</LabelText>
        </p>
        <div className="mt-12 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <a href="https://lancome.de" target="_blank" noValidate>
              <img className="h-12" src={lancomeLogo} alt="Lancome" />
            </a>
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <a
              href="https://www.gigaset.com/de_de/cms/telefone-uebersicht.html"
              target="_blank"
              noValidate
            >
              <img className="h-12" src={gigasetLogo} alt="Gigaset" />
            </a>
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <a
              href="https://www.petspremium.de/marken/wolfsblut/hund-nassfutter.html"
              target="_blank"
              noValidate
            >
              <img className="h-12" src={petsPremiumLogo} alt="PetsPremium" />
            </a>
          </div>
          <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1">
            <a href="https://www.kiehls.de" target="_blank" noValidate>
              <img className="h-12" src={kiehlsLogo} alt="Kiehls" />
            </a>
          </div>
          <div className="col-span-2 flex justify-center md:col-span-2 lg:col-span-1">
            <a href="https://libro.at" target="_blank" noValidate>
              <img className="h-12" src={libroLogo} alt="Libro" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default LogoSection
