import React from "react"
import { graphql } from "gatsby"
import { PrismicRichText } from "@prismicio/react"
import Button from "../components/Button"
import Card from "../components/Card"
import CustomerCard from "../components/CustomerCard"
import CustomerLogo from "../components/CustomerLogo"
import LabelText from "../components/LabelText"
import Layout from "../components/layout/Layout"
import Newsletter from "../components/layout/Newsletter"
import SplitSection from "../components/SplitSection"
import PressSection from "../components/PressSection"
import StatsSection from "../components/StatsSection"
import LogoSection from "../components/LogoSection"
import CornerstoneArticles from "../components/CornerstoneArticles"
import gigasetLogo from "../svg/gigaset-logo-color.svg"
import loreallogo from "../svg/loreal-logo.svg"
import telefonicaLogo from "../svg/telefonica-logo.svg"
import heroDesk from "../images/hero-desktop-without-hand-v3.jpg"
import heroVideo from "../images/homepage-video-cropped-v2.mp4"
import { formatPrismicLang } from "../utils/formatPrismicLang"

const Homepage = ({ data }) => {
  const prismicNode = data.allPrismicHomepage.edges.slice(0, 1).pop().node
  const homepageData = prismicNode.data
  const cornerstonePosts = data.allPrismicPost.edges

  const lang = formatPrismicLang(prismicNode)
  const isLangDe = lang === "de"

  return (
    <Layout language={lang} smoothScrolling={true}>
      <section className="md:pt-16 lg:pt-16 xl:pt-16">
        <div className="container mx-auto px-8 lg:flex">
          <div className="text-center pt-8 lg:text-left lg:w-1/2">
            <h1 className="text-4xl lg:text-5xl xl:text-6xl leading-none font-semibold">
              {homepageData.section_1[0].title.text}
            </h1>
            <p className="text-l lg:text-m lg:pr-12 mt-12 font-light">
              <PrismicRichText
                field={homepageData.section_1[0].description.richText}
              />
            </p>
            <div className="mt-8 md:mt-12">
              <Button size="xl" link={homepageData.cta_button_link.url}>
                {homepageData.cta_button_text.text}
              </Button>
            </div>
            <div className="container mx-auto lg:mt-16">
              <p className="mt-4 text-gray-700">
                {homepageData.section_1_logo_heading.text}
              </p>
              <div className="flex flex-row mt-4">
                <CustomerLogo src={loreallogo} />
                <CustomerLogo src={telefonicaLogo} />
                <CustomerLogo src={gigasetLogo} />
              </div>
            </div>
          </div>
          <div className="front-page  lg:w-1/2">
            {isLangDe ? (
              <div
                className="page-hero_visual page-hero_visual--home"
                data-popup="hero-video"
              >
                <img
                  className="page-hero_visual-image"
                  src={heroDesk}
                  alt="guided-selling-chatchamp"
                />
                <video
                  className="home-video is-visible"
                  src={heroVideo}
                  autoPlay
                  muted
                  loop
                  playsInline
                />
              </div>
            ) : (
              <img src={homepageData.section_1[0].image.url} />
            )}
          </div>
        </div>
      </section>
      <section id="companies" className="py-20 lg:pt-32">
        <LogoSection heading={homepageData.logo_section_heading.text} />
      </section>
      {homepageData.chatbot_vs_wizard_title?.text && (
        <section id="wizard-vs-chat">
          <div className="container mx-auto text-center hidden md:block lg:block xl:block">
            <h2 className="text-3xl md:text-5xl lg:text-5xl font-semibold">
              {homepageData.chatbot_vs_wizard_title.text}
            </h2>
          </div>
          {homepageData.section_chatbot_vs_wizard.map((section, index) => (
            <SplitSection
              key={`explanation-section1-${index}`}
              id={`explanation-section-${index}`}
              title={section.title.text}
              imageUrl={section.image.url}
              description={section.description.richText}
              reverseOrder={index % 2}
              buttonTitle={homepageData.cta_button_text.text}
              buttonLink={homepageData.cta_button_link.url}
            />
          ))}
        </section>
      )}
      {homepageData.chatbot_and_wizard_title?.text && (
        <section id="wizard-chat-combination" className="lg:pt-16">
          <div className="container mx-auto text-center hidden md:block lg:block xl:block">
            <h2 className="text-3xl md:text-5xl lg:text-5xl font-semibold">
              {homepageData.chatbot_and_wizard_title.text}
            </h2>
          </div>
          {homepageData.section_chatbot_and_wizard.map((section, index) => (
            <SplitSection
              key={`explanation-section2-${index}`}
              id={`explanation-section-${index}`}
              title={section.title.text}
              imageUrl={section.image.url}
              description={section.description.richText}
              reverseOrder={index % 2}
              buttonTitle={homepageData.cta_button_text.text}
              buttonLink={homepageData.cta_button_link.url}
            />
          ))}
        </section>
      )}
      <section id="why" className="py-20 lg:pb-32">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl lg:text-5xl font-semibold">
            {homepageData.section_2_title.text}
          </h2>
          <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
            {homepageData.section_2.map((section, index) => (
              <div key={index} className="flex-1 px-3">
                <Card className="mb-8">
                  <p className="font-semibold text-xl">{section.title.text}</p>
                  <p className="mt-4">
                    <PrismicRichText field={section.description.richText} />
                  </p>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id="features">
        <div className="container mx-auto text-center hidden md:block lg:block xl:block">
          <h2 className="text-3xl md:text-5xl lg:text-5xl font-semibold">
            {homepageData.section_3_title.text}
          </h2>
        </div>
        {homepageData.section_3.map((section, index) => (
          <SplitSection
            key={`explanation-section3-${index}`}
            id={`explanation-section-${index}`}
            title={section.title.text}
            imageUrl={section.image.url}
            description={section.description.richText}
            reverseOrder={index % 2}
            buttonTitle={homepageData.cta_button_text.text}
            buttonLink={homepageData.cta_button_link.url}
          />
        ))}
      </section>
      <section id="stats" className="py-20 lg:pt-32">
        <StatsSection
          heading={homepageData.stats_heading.text}
          secondaryText1={homepageData.stats_text_1.text}
          secondaryText2={homepageData.stats_text_2.text}
          secondaryText3={homepageData.stats_text_3.text}
        />
      </section>
      <section className="container mx-auto my-20 py-8 lg:py-24 bg-gray-200 rounded-lg text-center">
        <h3 className="text-2xl lg:text-5xl font-semibold px-8">
          {homepageData.cta_heading.text}
        </h3>
        <p className="mt-8 text-m lg:text-xl font-light pr-16 pl-16">
          {homepageData.cta_description.text}
        </p>
        <p className="mt-8">
          <Button size="xl" link={homepageData.cta_button_link.url}>
            {homepageData.cta_button_text.text}
          </Button>
        </p>
      </section>
      <section id="testimonials" className="py-20">
        <div className="container mx-auto">
          <LabelText className="mb-8 text-gray-700 text-center">
            {homepageData.customer_quotes_heading.text}
          </LabelText>
          <div className="flex flex-col md:flex-row md:-mx-3">
            {homepageData.customer_quotes.map((customer_quote, index) => (
              <div key={index} className="flex-1 px-3">
                <div className="py-4">
                  <CustomerCard customer={customer_quote} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {cornerstonePosts.length > 0 && (
        <CornerstoneArticles cornerstonePosts={cornerstonePosts} />
      )}
      {lang === "de" && (
        <>
          <section id="press" className="py-20">
            <PressSection />
          </section>
          <Newsletter />
        </>
      )}
    </Layout>
  )
}

export const pageQuery = graphql`
  query HomepageQuery($lang: String) {
    allPrismicPost(
      sort: { fields: data___date, order: DESC }
      filter: { data: { is_cornerstone_article: { eq: true } } }
    ) {
      edges {
        node {
          id
          uid
          type
          data {
            is_cornerstone_article
            title {
              richText
            }
            date
            hero_image {
              alt
              copyright
              url
              gatsbyImageData
            }
            preview_text {
              richText
            }
            meta_author {
              text
            }
            meta_author_image {
              alt
              copyright
              url
              gatsbyImageData
            }
            article_length {
              text
            }
          }
        }
      }
    }
    allPrismicHomepage(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          lang
          data {
            section_1 {
              title {
                text
              }
              description {
                richText
              }
              image {
                alt
                copyright
                url
                gatsbyImageData
              }
            }
            section_1_logo_heading {
              text
            }
            logo_section_heading {
              text
            }
            section_2_title {
              text
            }
            section_2 {
              title {
                text
              }
              description {
                richText
              }
            }
            section_3_title {
              text
            }
            section_3 {
              title {
                text
              }
              description {
                richText
              }
              image {
                alt
                copyright
                url
                gatsbyImageData
              }
            }
            chatbot_vs_wizard_title {
              text
            }
            section_chatbot_vs_wizard {
              title {
                text
              }
              description {
                richText
              }
              image {
                alt
                copyright
                url
                gatsbyImageData
              }
            }
            chatbot_and_wizard_title {
              text
            }
            section_chatbot_and_wizard {
              title {
                text
              }
              description {
                richText
              }
              image {
                alt
                copyright
                url
                gatsbyImageData
              }
            }
            stats_heading {
              text
            }
            stats_text_1 {
              text
            }
            stats_text_2 {
              text
            }
            stats_text_3 {
              text
            }
            cta_heading {
              text
            }
            cta_description {
              text
            }
            cta_button_text {
              text
            }
            cta_button_link {
              url
            }
            customer_quotes_heading {
              text
            }
            customer_quotes {
              title {
                text
              }
              customer_name {
                text
              }
              customer_position {
                text
              }
              content_type {
                text
              }
              quote {
                text
              }
              avatar {
                alt
                copyright
                url
                gatsbyImageData
              }
              card_image {
                alt
                copyright
                url
                gatsbyImageData
              }
              customer_link {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default Homepage
