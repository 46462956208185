import React from "react"
import { linkResolver } from "../utils/linkResolver"
import { PrismicText } from "@prismicio/react"

const BlogPostCard = ({ post }) => (
  <div className="flex flex-col rounded-lg shadow-lg overflow-hidden">
    <div className="shrink-0">
      <a href={linkResolver(post.node)}>
        <img
          className="h-48 w-full object-cover"
          src={post.node.data.hero_image.url}
          alt=""
        />
      </a>
    </div>
    <div className="flex-1 bg-white p-6 flex flex-col justify-between">
      <div className="flex-1">
        <p className="text-sm leading-5 font-medium text-indigo-600">
          <a href={linkResolver(post.node)} className="hover:underline">
            Blog Post
          </a>
        </p>
        <a href={linkResolver(post.node)} className="block">
          <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
            <PrismicText field={post.node.data.title.richText} />
          </h3>
          <p className="mt-3 text-base leading-6 text-gray-600">
            <PrismicText field={post.node.data.preview_text.richText} />
          </p>
        </a>
      </div>
      <div className="mt-6 flex items-center">
        <div className="shrink-0">
          <a href={linkResolver(post.node)}>
            <img
              className="h-10 w-10 rounded-full"
              src={post.node.data.meta_author_image.url}
              alt={post.node.data.meta_author_image.alt}
            />
          </a>
        </div>
        <div className="ml-3">
          <p className="text-sm leading-5 font-medium text-gray-900">
            <a href={linkResolver(post.node)} className="hover:underline">
              {post.node.data.meta_author.text}
            </a>
          </p>
          <div className="flex text-sm leading-5 text-gray-600">
            <time>{post.node.data.date}</time>
            <span className="mx-1">&middot;</span>
            <span>{post.node.data.article_length.text}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default BlogPostCard
